import React from "react"
import { Link, graphql } from "gatsby"
import queryString from 'query-string'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PricingSection from "../components/pricing_section"

class Pricing extends React.Component {

  componentWillMount() {
    var queryParams = (typeof window !== 'undefined') && document.referrer ? queryString.parse(document.referrer.substring(document.referrer.indexOf("?"))) : {};
    if (queryParams.ref) {
      window.history.replaceState(null, null, "?ref=" + queryParams.ref);
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Pricing" slug="/pricing/" />
        <PricingSection />
      </Layout>
    )
  }
}

export default Pricing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
