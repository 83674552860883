import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

export default class PricingSection extends React.Component {
  state = {
    annually: true
  };

  handleMonthlyClicked() {
    this.setState({ annually: false });
  }

  handleAnnuallyClicked() {
    this.setState({ annually: true });
  }

  render() {
    return (
      <div className="pricing">
        <div className="option-table wide-table">
          <div className="option-row">
            <div className="option-cell">
            </div>
            <div className="option-cell">
              Free
    </div>
            <div className="option-cell">
              <span className="callout-pill">Recommended</span>
              Standard
    </div>
            <div className="option-cell">
              Enterprise
    </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              <div className="toggle">
                <div className="toggle-options">
                  <div className={this.state.annually ? "" : "selected"} onClick={this.handleMonthlyClicked.bind(this)}>Monthly</div>
                  <div className={this.state.annually ? "selected" : ""} onClick={this.handleAnnuallyClicked.bind(this)}>Annually</div>
                </div>
              </div>
            </div>
            <div className="option-cell">
              <h2>$0 <span className="monthly">/ month<br /><br /></span></h2>
            </div>
            <div className="option-cell">
              <h2>{this.state.annually ? "$39" : "$49"} <span className="monthly">/ month{this.state.annually ? <span>{","}<br />{"billed annually"}</span> : <span><br /><br /></span>}</span></h2>
            </div>
            <div className="option-cell">
              <h2 className="contact-header">Contact</h2>
            </div>
          </div>
          <div className="option-row description">
            <div className="option-cell">
              <div className="toggle-label">
                Save <b>20%</b> by paying annually
                </div>
            </div>
            <div className="option-cell">
              All the basics. Unlimited Hyperlinks and clicks.
          </div>
            <div className="option-cell">
              Branded links. Custom domain included or bring your own.
          </div>
            <div className="option-cell">
              Scale your business. White label & custom solutions.
          </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <a className="button" href="../app/">Get Started</a>
            </div>
            <div className="option-cell">
              <a className="button primary" href={this.state.annually ? "../app/" : "../app/"}>Get Started</a>
            </div>
            <div className="option-cell">
              <a className="button" href="mailto:enterprise@usehyperlink.com">Get Quote</a>
            </div>
          </div>
          <div className="option-row heading">
            <div className="option-cell big">
              Features
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Links
    </div>
            <div className="option-cell">
              100
    </div>
            <div className="option-cell">
              Unlimited
    </div>
            <div className="option-cell">
              Unlimited
    </div>
          </div>


          <div className="option-row">
            <div className="option-cell">
              Clicks tracked
    </div>
            <div className="option-cell">
              Unlimited
    </div>
            <div className="option-cell">
              Unlimited
    </div>
            <div className="option-cell">
              Unlimited
    </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Branded links
    </div>
            <div className="option-cell">
              -
    </div>
            <div className="option-cell">
              Unlimited
    </div>
            <div className="option-cell">
              Unlimited
    </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Free custom domain
    </div>
            <div className="option-cell">
              -
    </div>
            <div className="option-cell">
              1
    </div>
            <div className="option-cell">
              Custom
    </div>
          </div>
          <div className="option-row heading">
            <div className="option-cell big">
              More Features
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Blazing fast redirects
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Custom URL slugs
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>

          <div className="option-row">
            <div className="option-cell">
              Editable destination URLs
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>

          <div className="option-row">
            <div className="option-cell">
              QR Codes
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>

          <div className="option-row">
            <div className="option-cell">
              Live dashboards
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Chrome extension
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Mobile app
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Web application
    </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Email & phone support
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Custom SSL certificate
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Dedicated IP address
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
              Dedicated Engineer support
    </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <i className="material-icons green">check_circle</i>
            </div>
          </div>
          <div className="option-row">
            <div className="option-cell">
            </div>
            <div className="option-cell">
              <a className="button" href="../app/">Get Started</a>
            </div>
            <div className="option-cell">
              <a className="button primary" href={this.state.annually ? "../app/" : "../app/"}>Get Started</a>
            </div>
            <div className="option-cell">
              <a className="button" href="mailto:enterprise@usehyperlink.com">Get Quote</a>
            </div>
          </div>
        </div>

        <div className="option-section">
          <div className="option-table header-table">
            <div className="option-row">
              <div className="option-cell">
                <div className="toggle">
                  <div className="toggle-options">
                    <div className={this.state.annually ? "" : "selected"} onClick={this.handleMonthlyClicked.bind(this)}>Monthly</div>
                    <div className={this.state.annually ? "selected" : ""} onClick={this.handleAnnuallyClicked.bind(this)}>Annually</div>
                  </div>
                </div>
                <div className="toggle-label">
                  Save <b>10%</b> by paying annually
                </div>
              </div>
            </div>
            <div className="option-row">
              <div className="option-cell heading-cell">
                <span className="callout-pill-inline">Recommended</span>
                Standard
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                <h2>{this.state.annually ? "$9" : "$10"} <span className="monthly">/ month{this.state.annually ? <span>{","}<br />{"billed annually"}</span> : <span><br /><br /></span>}</span></h2>
              </div>
            </div>
            <div className="option-row description">
              <div className="option-cell">
                Branded links. Custom domain included or bring your own.
              <a className="button primary" href={this.state.annually ? "../app/" : "../app/"}>Get Started</a>
              </div>
            </div>
          </div>
          <div className="option-table skinny-table">
            <div className="option-row">
              <div className="option-cell">
                Links
      </div>
              <div className="option-cell">
                100
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                Blazing fast redirects
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Custom URL slugs
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>

            <div className="option-row">
              <div className="option-cell">
                Clicks tracked
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Branded links
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Free custom domain
      </div>
              <div className="option-cell">
                1
      </div>
            </div>
          </div>
        </div>

        <div className="option-section">
          <div className="option-table header-table">
            <div className="option-row">
              <div className="option-cell heading-cell">
                Free
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                <h2>$0 <span className="monthly">/ month<br /><br /></span></h2>
              </div>
            </div>
            <div className="option-row description">
              <div className="option-cell">
                All the basics. Unlimited Hyperlinks and clicks.
              <a className="button" href="../app/">Get Started</a>
              </div>
            </div>

          </div>
          <div className="option-table skinny-table">
            <div className="option-row">
              <div className="option-cell">
                Links
      </div>
              <div className="option-cell">
                Unlimited
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                Blazing fast redirects
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Custom URL slugs
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>

            <div className="option-row">
              <div className="option-cell">
                Clicks tracked
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Branded Links
      </div>
              <div className="option-cell">
                -
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Free custom domain
      </div>
              <div className="option-cell">
                -
      </div>
            </div>
          </div>
        </div>

        <div className="option-section">
          <div className="option-table header-table">
            <div className="option-row">
              <div className="option-cell heading-cell">
                Enterprise
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                <h2 className="contact-header">Contact us</h2>
              </div>
            </div>
            <div className="option-row description">
              <div className="option-cell">
                Scale your business. White label & custom solutions.
              <a className="button" href="mailto:enterprise@usehyperlink.com">Contact Us</a>
              </div>
            </div>
          </div>
          <div className="option-table skinny-table">
            <div className="option-row">
              <div className="option-cell">
                Links
      </div>
              <div className="option-cell">
                Unlimited
      </div>
            </div>
            <div className="option-row">
              <div className="option-cell">
                Blazing fast redirects
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Custom URL slugs
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>

            <div className="option-row">
              <div className="option-cell">
                Clicks tracked
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Branded links
      </div>
              <div className="option-cell">
                Unlimited
      </div>

            </div>
            <div className="option-row">
              <div className="option-cell">
                Free custom domain
      </div>
              <div className="option-cell">
                Custom
      </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
